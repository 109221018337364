import {Button, DropDownButton, ToolbarSpacer} from '@progress/kendo-react-buttons';
import {Popup} from "@progress/kendo-react-popup";
import {Menu, MenuItem} from '@progress/kendo-react-layout';
import {DataResult, filterBy, process} from '@progress/kendo-data-query';
import {Grid, GridColumn as Column, GridToolbar} from '@progress/kendo-react-grid';
import {SplitPane} from 'react-multi-split-pane';
import moment from 'moment';
import debounce from 'lodash.debounce';
import FileAccessToken from '../../../helpers/fileAccessToken';
import BaseComponent from '../../../Components/BaseComponent';
import {IComboboxItem, ISortGridItem, simpleObject} from '../../../helpers/interfaces';
import {RunScriptAsync} from '../../../helpers/runscripts';
import {DataServerHost, isConterra} from '../../../helpers/settings';
import {UploadFile} from '../../../helpers/queries';

import Loader from '../../../Components/Common/Loader';
import ClearableInput from '../../../Components/Common/Form/ClearableInput';
import {IColumnValue, IGridFilter, IGridFilterItem} from '../../../Components/Dashboard/interfaces';
import BooleanFilter from '../../../Components/Dashboard/BooleanFilter';
import {
    DEFAULT_OPERATOR,
    GetDefaultGridFilter,
    GridRowHeight,
    IsComplexGridFilter
} from '../../../Components/Dashboard/helpers';
import ComboboxFilterVirtual from '../../../Components/Dashboard/ComboboxFilterVirtual';
import CustomColumnMenu from '../../../Components/Dashboard/ColumnMenu';
import gridStyles from '../../../Components/Dashboard/dashboard.module.scss';
import ComboboxFilter from '../../../Components/Dashboard/ComboboxFilter';
import {ModalRef} from '../../../Components/Common/Modal/Modal';
import OpenCardLink from '../../../Components/Common/Buttons/OpenCardLink';

import {downloadCLResultsAction, IColumn, IDocumentItem, IFileItem, IUpdateIncludedParams, props} from './interfaces';
import ChecklistResultHistory from './ChecklistResultHistory'; // todo rename  ChecklistResultInfo
import ChecklistResultCarousel from './PreviewerCarousel';
import IncludeCheckbox from './IncludedCheckBox';
import styles from './checklistResults.module.scss';
import React from 'react';
import {TextArea} from '@progress/kendo-react-inputs';
import {
    CreateResultFromVersion,
    FIELD_DETAIL_LIST,
    GetContextMenuItems,
    HAS_MEDIA_LIST,
    IsDisableChangeIncluded,
    SendCliIncuded,
    TYPES,
    UpdateCliComments
} from './helpers';
import {ICLMSettings, ICLPMBPItem, ICLPMWOItem} from '../../../stores/interfaces';
import CLPMSettings from '../../../stores/CLPMSettings';
import {GetCheckListSampleUrl} from '../helpers';
import CardManagement from "../../../Components/Cards/CardManagement";

interface state {
    bploading: boolean;
    loading: boolean;
    documentLoading: boolean;
    imgLoading: boolean;
    documents: DataResult;
    sort: Array<ISortGridItem>;
    buildPlans: Array<IComboboxItem>;
    filteredWorkOrders: ICLPMWOItem[];
    gridFilter: IGridFilter;
    contextMenu: null | { left: number, top: number; };
    currentFileId: number | null;
    disableNext: boolean;
    disablePrev: boolean;
    remountFilterKey: number;
    gridLoading: boolean;
}

class ChecklistResults extends BaseComponent<props, state> {
    contextMenuItem: IDocumentItem | null = null;
    columns: Array<IColumn> = [];
    gridRef: any;
    bpId: number | undefined = this.props.buildPlanId ? +this.props.buildPlanId : undefined;
    woId: number | undefined = this.props.workOrderId ? +this.props.workOrderId : undefined;
    selectedBP: ICLPMBPItem | undefined;
    selectedWorkOrder: ICLPMWOItem | undefined = undefined;
    selectedDocument: IDocumentItem | undefined;
    currentFileId: number | null = null;
    group: Array<{ field: string, dir?: 'asc' | 'desc'; }> = [{
        field: 'groupName',
        dir: "asc"
    }];
    sort: Array<ISortGridItem> = [];
    gridFilter: IGridFilter = GetDefaultGridFilter();
    expandAll: boolean = false;
    filterChangeTimeout: any;
    documents: Array<IDocumentItem> = [];
    columnValues: { [key: string]: Array<IColumnValue>; } = {
        status: [],
        HasMedia: HAS_MEDIA_LIST.map(item => ({
            ...item,
            Selected: true,
            FieldId: 'HasMedia'
        })),
        FieldDetail: FIELD_DETAIL_LIST.map(item => ({
            ...item,
            Selected: true,
            FieldId: 'FieldDetailId'
        })),
    };
    expandedForBPId: number | null = null;
    expandedGroups: { [key: string]: boolean; } = {};
    isInsideRightPane: boolean = false;
    IsFocusOnCommentField = false;
    settings: ICLMSettings | null = null;
    type: IComboboxItem | null = null;

    constructor(props: any) {
        super(props);
        this.state = {
            bploading: false,
            loading: false,
            documentLoading: false,
            gridLoading: false,
            imgLoading: false,
            buildPlans: [],
            filteredWorkOrders: [],

            documents: {data: [], total: 0},
            gridFilter: this.gridFilter,
            sort: [],

            contextMenu: null,
            currentFileId: null,
            disableNext: false,
            disablePrev: false,
            remountFilterKey: +new Date(),
        };
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        if (this.filterChangeTimeout) clearTimeout(this.filterChangeTimeout);
        window.document.removeEventListener('keydown', this.OnKeyDownDocument);
    }

    async componentDidMount() {
        window.document.addEventListener('keydown', this.OnKeyDownDocument);
        this.settings = await CLPMSettings.getSettings();
        this.SetColumnsSettings();
        this.SetStatusesList();
        if (this.props.isCLM) {
            this.LoadClmBuildPlans();
        } else {
            this.LoadData();
        }
        window.helpers.onInactive(300000, this.AutoRefresh);
    }

    componentDidUpdate(prevProps: props) {
        if (this.props.isActive) {
            let updateBPorWO = false;

            if (!this.props.isCLM && this.props.buildPlanId && this.bpId !== this.props.buildPlanId) {
                this.bpId = this.props.buildPlanId;
                updateBPorWO = true;
            } else if (this.props.isCLM) {
                if (
                    (this.props.buildPlanId && this.bpId !== this.props.buildPlanId && prevProps.buildPlanId !== this.props.buildPlanId) ||
                    (this.props.workOrderId && prevProps.workOrderId !== this.props.workOrderId)
                ) {
                    this.bpId = this.props.buildPlanId;
                    updateBPorWO = true;
                }
                let prevWoId = prevProps.workOrderId;
                let woId = this.props.workOrderId;
                if (/*woId && prevWoId && */woId !== prevWoId && this.selectedWorkOrder?.Id !== woId) {
                    updateBPorWO = true;
                    this.woId = woId
                    this.selectedWorkOrder = undefined
                    this.GetSetFilteredWorkOrders()
                }
            }

            if (updateBPorWO === true) {
                this.selectedDocument = undefined;
                this.LoadData();
            }
        }
    }

    initGridRef = (ref: any) => {
        if (!this.gridRef) {
            this.gridRef = ref;
        }
    };

    SetStatusesList = async () => {
        let statuses = await CLPMSettings.getStatusesList();
        this.columnValues.status = [];
        statuses.forEach((st) => {
            this.columnValues.status.push({
                Id: st.value,
                Name: st.text || 'No Result',
                Selected: true,
                FieldId: 'status'
            });
        });
    };

    SetColumnsSettings = () => {
        this.columns = [];

        if (this.settings?.IsReviewer || this.settings?.IsCustomer) {
            this.columns.push({
                field: 'included',
                title: ' ',
                width: 40
            });
        }
        this.columns.push(
            {
                field: 'index',
                title: '#',
                width: 50,
                filterable: false,
            },
            {
                field: 'cliName',
                title: 'Item',
            },
            {
                field: 'statusName',
                fieldId: 'status',
                title: 'Status',
                width: 150,
            },
            {
                field: 'HasMedia',
                fieldId: 'HasMedia',
                title: 'Media',
                width: 80,
                filterable: false,
            },
            {
                field: 'FieldDetail',
                fieldId: 'FieldDetail',
                title: 'Detail',
                width: 100,
                filterable: false,
            }
        );
    };

    render() {
        let contextItems = !!this.contextMenuItem && GetContextMenuItems(this.settings!, this.contextMenuItem) || [];
        let isSubmitter = this.settings?.IsSubmitter;
        return <>
            {this.state.loading && <Loader style={{zIndex: 99999}}/>}
            <SplitPane split="vertical" defaultSizes={[1, 1, 0.2]}
                       minSize={[0, 0, 170]}>
                <div style={{position: 'relative', width: '100%'}}>
                    {this.state.gridLoading && <Loader style={{zIndex: 99999}}/>}
                    <Grid
                        className={styles.Grid}
                        style={{height: '100%', width: '100%'}}
                        data={this.state.documents}
                        groupable={false}
                        group={this.group}
                        onExpandChange={this.ExpandChange}
                        expandField="expanded"
                        selectedField="Selected"
                        onRowClick={this.OnClickRow}
                        filterable={true}
                        filterCellRender={this.renderFilterCell}
                        filter={this.state.gridFilter}
                        sortable={true}
                        sort={this.state.sort}
                        onSortChange={this.OnSort}
                        rowHeight={GridRowHeight}
                        cellRender={this.renderCell}
                        rowRender={this.renderRow}
                        ref={this.initGridRef}
                    >
                        <GridToolbar>
                            {this.props.isCLM &&
                                <div className={styles.CLMFilterRow}>
                                    <ComboboxFilterVirtual
                                        key={this.state.remountFilterKey + 'bps'}
                                        data={this.state.buildPlans}
                                        loading={this.state.bploading}
                                        onChange={this.OnChangeBuildPlan}
                                        filter={{
                                            id: 'buildPlans',
                                            placeholder: 'Filter by Build Plan',
                                            type: 'combobox',
                                            width: 300
                                        }}
                                        defaultValue={this.selectedBP}
                                        required={true}
                                    />
                                    {!isSubmitter && <OpenCardLink
                                        refName='FSMBuildPlans'
                                        dataAttr={this.bpId}
                                        text="Open BP"
                                        isBtn={true}
                                        style={{marginLeft: 8}}
                                    />}
                                    <ToolbarSpacer/>
                                    {this.renderRightButtons()}
                                </div>
                            }
                            <div className={styles.CLMFilterRow}>
                                <ComboboxFilter
                                    key={this.state.remountFilterKey + 'wos'}
                                    defaultValue={this.selectedWorkOrder}
                                    filterData={this.state.filteredWorkOrders}
                                    onChange={this.ChangeFilterByWO}
                                    filter={{
                                        id: 'workOrders',
                                        placeholder: 'Filter by Work Order',
                                        type: 'combobox',
                                        width: 300
                                    }}
                                />
                                <ComboboxFilter
                                    key={this.state.remountFilterKey + 'type'}
                                    defaultValue={this.type}
                                    filterData={TYPES}
                                    onChange={this.OnChangeTypeFilter}
                                    style={{margin: '0 8px'}}
                                    filter={{
                                        id: 'filterBeType',
                                        placeholder: 'Filter by Type',
                                        type: 'combobox',
                                        width: 160,
                                    }}
                                />
                                <ToolbarSpacer/>
                                <Button
                                    icon={'plus'}
                                    title={'Add WO CheckList'}
                                    onClick={this.OpenWOCheckListTemplateCard}
                                    disabled={this.selectedBP?.IsActive === false || this.selectedWorkOrder?.IsActive === false}
                                />
                                {!isSubmitter && <DropDownButton
                                    buttonClass={styles.DownloadButton}
                                    text="Download"
                                    icon="download"
                                    items={['Approved Only', 'All']}
                                    onItemClick={this.OnClickDownloadResults}
                                />}
                                {!this.props.isCLM && this.renderRightButtons()}
                            </div>
                        </GridToolbar>
                        {this.columns.map(this.renderColumn)}
                    </Grid>
                </div>
                <div style={{display: 'flex', width: '100%'}}>
                    <div className={styles.RightPanel}>
                        {!!this.bpId && <div className={styles.PreviewerCarouselBox}>
                            {this.selectedDocument &&
                                <ChecklistResultCarousel
                                    key={this.selectedDocument.id + '_' + this.selectedDocument.files.length}
                                    dataItem={this.selectedDocument}
                                    buildPlanId={this.bpId}
                                    pageId={this.props.pageId}
                                    onSelectFile={this.OnSelectFile}
                                    editComments={this.OpenCliCommentEdit}
                                    settings={this.settings!}
                                    rebuildBtn={<Button
                                        icon="reset"
                                        key={'rebuild' + this.currentFileId}
                                        className={styles.RebuildBtn}
                                        title="Rebuild Preview"
                                        onClick={this.RebuildPreview}
                                    />}
                                    cancelToken={() => {
                                        this.CancelPrevRequestUpdateToken();
                                        return this.cancelToken;
                                    }}
                                />}
                        </div>}
                    </div>
                    <div
                        style={{width: '170px', flex: '0 0 auto'}}
                        onMouseOver={this.OnMouseOverRightPanel}
                        onMouseOut={this.OnMouseOutRightPanel}
                    >
                        <div
                            className={styles.HistoryPanel}
                            onWheel={debounce((event: any) => {
                                let action: 'next' | 'prev' = event.nativeEvent.deltaY > 0 ? 'next' : 'prev';
                                this.NavResults(action);
                            }, 200)}>
                            {!!this.selectedDocument && <div className={styles.ResultsNav}>
                                <Button
                                    icon="caret-double-alt-left"
                                    data-action="prev"
                                    onClick={this.OnNavResults}
                                    fillMode='flat'
                                    disabled={this.state.disablePrev}
                                />
                                <span>Checklist Result</span>
                                <Button
                                    icon="caret-double-alt-right"
                                    data-action="next"
                                    onClick={this.OnNavResults}
                                    fillMode='flat'
                                    disabled={this.state.disableNext}
                                />
                            </div>}
                            {!!this.selectedDocument && !!this.selectedDocument.resultId &&
                                <ChecklistResultHistory
                                    key={this.selectedDocument.id}
                                    dataItem={this.selectedDocument}
                                    currentFileId={this.state.currentFileId}
                                    settings={this.settings!}
                                    refreshGrid={this.refreshGridFromChecklistResultHistory}
                                    onCommentChangeFocus={this.OnChangeCommentFocus}
                                    createResultFromVersion={this.CreateResultFromVersion}
                                />}
                        </div>
                    </div>
                </div>
            </SplitPane>

            <Popup
                show={!!contextItems}
                offset={this.state.contextMenu || undefined}
            >
                <Menu
                    vertical={true}
                    onSelect={this.OnSelectContextItem}
                >
                    {contextItems.map((item) => {
                        let key = item.Id;
                        return <MenuItem
                            key={key}
                            text={item.Name}
                            data={item.Id + ''}
                            cssClass={'cssClass'}
                        ></MenuItem>;
                    })}
                </Menu>
            </Popup>
        </>;
    }

    renderCell = (el: any, props: any) => {
        if (props.rowType === 'groupHeader') {
            if (el === null) return el;
            let items: Array<IDocumentItem> = props.dataItem.items;
            let itemsWithStatus = items.filter((item) => !!item.statusName);
            let dataItem = props.dataItem;
            let includeCheckbox: any = null;
            if (this.settings?.IsReviewer) {
                let item = items[0];
                let enableItemIndex = items.findIndex((item) => !IsDisableChangeIncluded(item, this.settings!));
                let notIncludeditem = items.findIndex((item) => !item.included);
                let included = notIncludeditem === -1;
                let groupName = item.groupName.replace(/\s/g, '') + item.clId;
                includeCheckbox = <IncludeCheckbox
                    key={groupName}
                    className={styles.GroupTDCheckbox}
                    checked={included}
                    disabled={enableItemIndex === -1}
                    groupName={groupName}
                    queryProps={{
                        Included: included,
                        CLID: item.clId,
                    }}
                    onChange={this.SendCliIncuded}
                />;
            }
            return <td colSpan={props.columnsCount}>
                <div className={styles.GroupTD}>
                    {el.props.children.props.children[0]}
                    {dataItem.value}
                    <span
                        className={styles.GroupTDNumbers}>({itemsWithStatus.length} - {props.dataItem.items.length})</span>
                    {includeCheckbox}
                </div>
            </td>;
        }

        if (props.field === 'included') {
            let dataItem: IDocumentItem = props.dataItem;
            let groupName = dataItem.groupName.replace(/\s/g, '') + dataItem.cliId;
            return <td key={groupName + dataItem.included.toString()}>
                <IncludeCheckbox
                    key={groupName}
                    checked={dataItem.included}
                    groupName={groupName}
                    disabled={IsDisableChangeIncluded(dataItem, this.settings!)}
                    queryProps={{
                        Included: dataItem.included,
                        CLID: dataItem.clId,
                        CLIID: dataItem.cliId
                    }}
                    onChange={this.SendCliIncuded}
                />
            </td>;
        }
        return el;
    };

    renderColumn = (column: IColumn) => {
        return <Column
            key={column.field}
            field={column.field}
            title={column.title}
            width={column.width}
            columnMenu={column.fieldId ? this.renderColumnMenu : undefined}
            headerClassName={column.fieldId ? `${column.field} ${this.GetColumnHeaderClass(column.field, column.fieldId)}` : ''}
            filterable={column.filterable}
        />;
    };

    renderRow = (row: any, props: any) => {
        let isAvailableContextMenu = this.settings?.IsReviewer || this.settings?.IsSubmitter;
        return <tr
            {...row.props}
            onContextMenu={isAvailableContextMenu ? (e: any) => this.OnContextMenuRow(e, props.dataItem) : undefined}
            className={row.props.className}
        >
            {row.props.children}
        </tr>;
    };

    renderRightButtons = () => {
        return <>
            <Button
                style={{marginLeft: 8}}
                themeColor={this.gridFilter.filters.length ? 'primary' : undefined}
                icon={'filter-clear'}
                title={'Set Default Filters'}
                onClick={this.SetDefaultFilters}
            />
            <Button
                style={{marginLeft: 8}}
                iconClass={`${styles.ExpandIcon} mdi mdi-${this.expandAll ? 'collapse-all-outline' : 'expand-all-outline'}`}
                title={this.expandAll ? 'Collapse All' : 'Expand All'}
                onClick={this.ToggleExpandGroup}
            />
            <Button
                style={{marginLeft: 8}}
                icon="refresh"
                onClick={this.Refresh}
            />
        </>;
    };

    renderFilterCell = (el: any, props: simpleObject) => {
        let field = props.field;
        let key = field + this.state.remountFilterKey;
        if (field === 'included') {
            return <BooleanFilter
                key={key}
                trueText="Included"
                falseText="Excluded"
                id="Included"
                onChange={this.OnIncludedFilterChange}
            />;
        }
        let value = '';
        let filter = this.gridFilter.filters.find((f) => !IsComplexGridFilter(f) && f.field === field);
        value = filter && !IsComplexGridFilter(filter) ? filter.value : '';

        return <ClearableInput
            key={key}
            defaultValue={value}
            dataprops={field}
            onChange={this.OnChangeTextGridFilter}
            clear={this.ClearTextGridFilter}
        />;
    };

    OnMouseOverRightPanel = () => {
        this.isInsideRightPane = true;
    };

    OnMouseOutRightPanel = () => {
        this.isInsideRightPane = false;
    };

    OnChangeCommentFocus = (focus: boolean) => {
        this.IsFocusOnCommentField = focus;
    };

    OnKeyDownDocument = (event: any) => {
        if (this.props.isActive && !this.IsFocusOnCommentField) {
            let keyCodes = [61, 107, 173, 109, 187, 189];
            if ((event.ctrlKey === true && keyCodes.indexOf(event.which) !== -1)) {
                event.preventDefault();
            }

            let action: 'next' | 'prev' | '' = event.which == 40 ? 'next' : event.which == 38 ? 'prev' : '';
            if (this.isInsideRightPane && action) {
                this.NavResults(action);
                event.preventDefault();
            }
        }
    };

    OnSelectFile = (fileId: number) => {
        if (this.selectedDocument) {
            this.currentFileId = fileId;
            this.setState({currentFileId: fileId});
        }
    };

    OpenWOCheckListTemplateCard = () => {
        CardManagement.OpenWOChecklistCard({
            buildPlanId: this.bpId,
            workOrderId: this.woId,
            onFinish: this.Refresh
        })
    }

    RebuildPreview = async () => {
        try {
            this.setState({loading: true});
            await this.GetSQLData({
                spName: 'UpdateFilePreview',
                path: 'Clm',
                params: {fileId: this.currentFileId},
                paramsToQuery: true
            });
        } finally {
            this.LoadData();
        }
    };

    OnContextMenuRow = (e: any, dataItem: IDocumentItem) => {
        e.preventDefault();
        this.contextMenuItem = dataItem;
        let left = e.nativeEvent.clientX;
        let top = e.nativeEvent.clientY;
        this.setState({contextMenu: {left, top}});
        document.addEventListener("click", this.CloseContextMenu);
    };

    CreateResultFromVersion = async () => {
        try {
            var fileId = this.currentFileId;
            var dataItem = this.selectedDocument;
            if (!fileId || fileId && dataItem?.approvedFileId && +fileId === +dataItem.approvedFileId) return false;
            let scrollPosition = this.gridRef.vs.container.scrollTop;
            this.setState({loading: true});
            await CreateResultFromVersion(fileId);
            this.LoadData('grid', scrollPosition);
        } finally {
            this.setState({loading: false});
        }
    };

    renderColumnMenu = (props: any) => {
        let field = props.column.field;
        let column = this.columns.find((c) => c.field === field);
        return <CustomColumnMenu
            defaultProps={props}
            getColumnValues={(field, fieldId) => (this.columnValues[fieldId] || [])}
            filterByValues={this.OnChangeFilterByvalue}
            fieldId={column?.fieldId}
        />;
    };

    OnChangeFilterByvalue = (filters: IGridFilterItem[], values: IColumnValue[], fieldId: string, fieldName: string) => {
        this.columnValues[fieldId] = values;
        let isAllSelected = values.length === filters.length;
        let gridFilter = this.gridFilter;
        let complexFilter = gridFilter.filters.find((f) => IsComplexGridFilter(f) && !IsComplexGridFilter(f.filters[0]) && f.filters[0].field === fieldId);
        if (filters.length) {
            gridFilter.filters = gridFilter.filters.filter((f) => IsComplexGridFilter(f) && !IsComplexGridFilter(f.filters[0]) && f.filters[0].field !== fieldId || !IsComplexGridFilter(f) && f.field !== fieldName);
            complexFilter = GetDefaultGridFilter();
            complexFilter.logic = 'or';
            complexFilter.filters = filters;
            gridFilter.filters.push(complexFilter);
        } else {
            gridFilter.filters = gridFilter.filters.filter((f) => !IsComplexGridFilter(f) || IsComplexGridFilter(f) && !IsComplexGridFilter(f.filters[0]) && f.filters[0].field !== fieldId);
        }
        this.gridFilter = gridFilter;
        this.SetDocuments();
    };

    CloseContextMenu = () => {
        this.contextMenuItem = null;
        this.setState({contextMenu: null});
        document.removeEventListener("click", this.CloseContextMenu);
    };

    /*RemoveDocNameFilter = () => {
        this.gridFilter.filters = this.gridFilter.filters.filter((f) => {
            if (!IsComplexGridFilter(f)) return true;
            let firstfilter = f.filters[0];
            if (!IsComplexGridFilter(firstfilter) && firstfilter.field !== 'DocumentName') return true;
            return false;
        });
    };*/

    ClearTextGridFilter = (field: string) => {
        this.gridFilter.filters = this.gridFilter.filters.filter((f) => !IsComplexGridFilter(f) && f.field !== field);
        this.SetDocuments();
    };

    ClearAllData = () => {
        this.documents = []
        this.selectedDocument = undefined;
        this.currentFileId = null;
        this.setState({
            documents: {data: [], total: 0},
            remountFilterKey: +new Date()
        });
    }

    LoadData = async (mode?: 'grid' | 'background', scrollPosition?: number) => {
        if (!this.bpId) {
            this.ClearAllData()
            return;
        }
        let isFullUpdate = !mode;
        let isOnlyGridUpdate = mode === 'grid';
        if (isFullUpdate) this.setState({loading: true});
        else if (isOnlyGridUpdate) this.setState({gridLoading: true});
        try {
            let result: any = await this.GetSQLData({
                spName: 'CLPM_GetChecklistsResults',
                params: {
                    buildPlanId: this.bpId,
                    workOrderId: this.woId,
                }
            });
            this.documents = result[0] || [];
            let files: Array<IFileItem> = result[1] || [];
            files.forEach((file) => {
                // @ts-ignore
                file.e = file.e?.toUpperCase() || null;
                file.name = `Version ${file.v}. Uploaded (${file.d ? moment(file.d).format('L') : ''}) `;
            });

            let CLPMStatuses = await CLPMSettings.getCLPMStatusesLib();
            this.documents.forEach((item) => {
                if (this.selectedDocument && this.selectedDocument.id === item.id) {
                    item.Selected = true;
                    this.selectedDocument = item;
                }
                // @ts-ignore
                item.sampleExt = item.sampleExt?.toUpperCase() || null;
                item.files = files.filter((f) => f.resId === item.resultId);
                item.HasMedia = item.files.length ? 'Yes' : 'No';
                item.FieldDetailId = item.FieldDetail || 'null';
                // @ts-ignore
                item.statusName = CLPMStatuses[item.status];
            });
            let groupedGridData = process(this.documents, {
                group: this.group,
                filter: this.gridFilter
            });
            if (this.expandedForBPId !== this.bpId) {
                this.expandedGroups = {};
                this.expandedForBPId = this.bpId || null;
            }
            groupedGridData.data.forEach((group) => group.expanded = !!this.expandedGroups[group.value]);
            this.setState({
                documents: groupedGridData,
                ...this.GetDisabledArrows(groupedGridData),
                remountFilterKey: +new Date()
            }, () => {
                if (scrollPosition && scrollPosition > 0) {
                    if (this.gridRef && this.gridRef.vs.container.scroll) {
                        this.gridRef.vs.container.scroll(0, scrollPosition);
                    } else if (this.gridRef && this.gridRef.vs.container.scrollTop !== undefined) {
                        this.gridRef.vs.container.scrollTop = scrollPosition;
                    }
                }
            });
        } finally {
            if (isFullUpdate) this.setState({loading: false});
            else if (isOnlyGridUpdate) this.setState({gridLoading: false});
            if (isConterra) {
                let viewerImg = window.document.querySelector('#' + this.props.pageId + '-viewer .react-viewer-btn');
                // @ts-ignore
                if (viewerImg && viewerImg.click) {
                    //@ts-ignore
                    viewerImg.click();
                }
            }
        }
    };

    GetSetFilteredWorkOrders = async () => {
        const workOrders = await CLPMSettings.getActiveWorkOrders();
        const filteredWorkOrders = this.bpId ? workOrders.filter((wo) => wo.BuildPlanId === this.bpId) : workOrders
        this.selectedWorkOrder = this.woId ? filteredWorkOrders.find(wo => wo.Id === this.woId) : undefined
        this.woId = this.selectedWorkOrder && +this.selectedWorkOrder.Id
        this.setState({filteredWorkOrders, remountFilterKey: +new Date()});
    }

    LoadClmBuildPlans = async () => {
        this.setState({bploading: true});
        try {
            const {buildPlans, workOrders} = await CLPMSettings.getActiveBuildPlansAndWorkOrders();
            this.selectedBP = CLPMSettings.getSelectedBP(this.bpId) || undefined;
            this.bpId = this.selectedBP && +this.selectedBP.Id;
            const filteredWorkOrders = this.bpId ? workOrders.filter((wo) => wo.BuildPlanId === this.bpId) : workOrders
            this.selectedWorkOrder = this.woId ? filteredWorkOrders.find(wo => wo.Id === this.woId) : undefined
            this.woId = this.selectedWorkOrder && +this.selectedWorkOrder.Id
            this.setState({buildPlans, filteredWorkOrders, remountFilterKey: +new Date()});

            if (this.bpId) this.LoadData();
        } finally {
            this.setState({bploading: false});
        }
    };

    SendCliIncuded = async (queryParams: IUpdateIncludedParams) => {
        try {
            this.setState({loading: true});
            await SendCliIncuded(queryParams);
        } finally {
            this.LoadData('grid');
            this.setState({loading: false});
        }
    };

    OnChangeBuildPlan = async (value: ICLPMBPItem | null, filter: any) => {
        this.expandAll = false;
        this.selectedBP = value || undefined;
        this.bpId = value ? +value.Id : undefined;
        const workOrders = await CLPMSettings.getActiveWorkOrders()
        const filteredWorkOrders = this.bpId ? workOrders.filter(wo => wo.BuildPlanId === this.bpId) : workOrders
        this.selectedWorkOrder = this.selectedWorkOrder ? filteredWorkOrders.find(wo => wo.Id === this.selectedWorkOrder?.Id) : undefined
        if (this.props.isCLM) {
            if (value) localStorage.setItem(CLPMSettings.CLM_BUILDPLAN_LS, JSON.stringify(value));
            else localStorage.removeItem(CLPMSettings.CLM_BUILDPLAN_LS);
        }
        this.setState({filteredWorkOrders, remountFilterKey: +new Date()})
        this.LoadData();
    };

    OnSort = (e: any) => {
        this.setState({sort: e.sort});
        this.sort = e.sort;
        this.group[0].dir = e.sort[0]?.dir;
        this.SetDocuments();
    };

    OnClickRow = async (e: any) => {
        let selected = e.dataItem;
        if (selected.field === "groupName") return;
        if (this.selectedDocument && this.selectedDocument.id === selected.id) return;
        this.SelectRow(selected);
    };

    SelectRow = (selected: IDocumentItem) => {
        try {
            this.selectedDocument = selected;
            for (let item of this.documents) {
                item.Selected = item.id === selected.id;
            }
            this.setState((state) => ({...this.GetDisabledArrows(state.documents)}));
        } finally {
            this.setState({documentLoading: false});
        }
    };

    OnNavResults = (e: any) => {
        let selected = this.selectedDocument;
        if (!selected) return;
        let action: 'next' | 'prev' = e.currentTarget.dataset.action;
        this.NavResults(action);
    };

    NavResults = (action: 'next' | 'prev') => {
        let selected = this.selectedDocument;
        if (!selected) return;

        if (action === 'next' && this.state.disableNext || action === 'prev' && this.state.disablePrev) return;

        let selectedGroupName = selected.groupName;
        if (selectedGroupName && selectedGroupName.trim) selectedGroupName = selectedGroupName.trim();
        let groupedData = this.state.documents.data;
        for (let i = 0; i < groupedData.length; i++) {
            let group = groupedData[i];
            let groupName = group.value;
            if (groupName && groupName.trim) groupName = groupName.trim();
            if (groupName === selectedGroupName) {
                for (let j = 0; j < group.items.length; j++) {
                    let item = group.items[j];
                    if (item.id === selected.id) {
                        if (action === 'next') {
                            let nextGroup = groupedData[i + 1];
                            let nextItem = group.items[j + 1] || nextGroup.items[0];
                            selected.Selected = false;
                            nextItem.Selected = true;
                            this.selectedDocument = nextItem;
                        } else if (action === 'prev') {
                            let prevGroup = groupedData[i - 1];
                            let prevItem = group.items[j - 1] || prevGroup.items[prevGroup.items.length - 1];
                            selected.Selected = false;
                            prevItem.Selected = true;
                            this.selectedDocument = prevItem;
                        }
                        this.setState(this.GetDisabledArrows(this.state.documents));
                        break;
                    }
                }
            }
        }
    };

    refreshGridFromChecklistResultHistory = debounce((auto?: boolean) => {
        let data = filterBy([this.selectedDocument], this.state.gridFilter);
        let disabledNext = this.state.disableNext;
        if (!data.length) {
            if (!disabledNext) this.NavResults('next');
            else {
                this.selectedDocument!.Selected = false;
                this.selectedDocument = undefined;
                this.setState({
                    disableNext: true,
                    disablePrev: true
                });
            }
        }
        this.LoadData(auto ? 'background' : 'grid');
    }, 1000)

    GetDisabledArrows = (documents: DataResult) => {
        let selected = this.selectedDocument;
        let disableNext = false;
        let disablePrev = false;
        if (selected) {
            let selectedGroupName = selected.groupName;
            if (selectedGroupName && selectedGroupName.trim) selectedGroupName = selectedGroupName.trim();
            let groupedData = documents.data;
            for (let i = 0; i < groupedData.length; i++) {
                let group = groupedData[i];
                let groupName = group.value;
                if (groupName && groupName.trim) groupName = groupName.trim();
                if (groupName && selectedGroupName && groupName === selectedGroupName) {
                    for (let j = 0; j < group.items.length; j++) {
                        let item = group.items[j];
                        if (item.id === selected.id) {
                            let nextGroup = groupedData[i + 1];
                            disableNext = j === group.items.length - 1 && !nextGroup;
                            disablePrev = i === 0 && j === 0;
                            break;
                        }
                    }
                }
            }
        }
        return {disableNext, disablePrev};
    };

    SetDocuments = () => {
        let documents = process(
            this.documents,
            {
                group: this.group,
                sort: this.sort,
                filter: this.gridFilter
            }
        );
        documents.data.forEach((group) => group.expanded = !!this.expandedGroups[group.value]);
        this.setState({documents, gridFilter: this.gridFilter});
    };

    SetDefaultFilters = () => {
        this.selectedWorkOrder = undefined;
        this.type = null;
        this.gridFilter = GetDefaultGridFilter();
        for (let fieldId in this.columnValues) {
            for (let value of this.columnValues[fieldId]) value.Selected = true;
        }
        this.SetDocuments();
        this.setState({remountFilterKey: +new Date()});
    };

    ChangeFilterByWO = (value: ICLPMWOItem | undefined) => {
        if (!value || (this.selectedDocument && this.selectedDocument.woId !== value.Id)) {
            // todo unselect document
            this.selectedDocument = undefined;
            this.currentFileId = null;
        }
        this.selectedWorkOrder = value;
        this.woId = value ? +value.Id : undefined
        this.LoadData()
    };

    OnChangeTypeFilter = (value: IComboboxItem | null) => {
        this.type = value;
        let isDocCl = value?.Id === 'Documents';
        let field: keyof IDocumentItem = 'docCl';
        if (value && this.selectedDocument && this.selectedDocument[field] === isDocCl) {
            this.selectedDocument = undefined;
            this.currentFileId = null;
        }
        let gridFilter = this.gridFilter;
        this.gridFilter.filters = gridFilter.filters.filter((f) => !IsComplexGridFilter(f) && f.field === field ? false : true);
        if (value) {
            this.gridFilter.filters.push({field, value: isDocCl, operator: 'eq'});
        }
        this.SetDocuments();
    };

    OnChangeTextGridFilter = (e: any, field: any) => {
        let gridFilter = this.gridFilter;
        let value = e.value;
        if (field === 'statusName') {
            gridFilter.filters = gridFilter.filters.filter((f) => !IsComplexGridFilter(f));
            this.columnValues.status.forEach((s) => s.Selected = true);
        }
        let oldFilter = gridFilter.filters.find((f) => !IsComplexGridFilter(f) && f.field === field);
        if (oldFilter && !IsComplexGridFilter(oldFilter)) {
            oldFilter.value = value;
        } else {
            this.gridFilter.filters.push({
                field,
                value,
                operator: DEFAULT_OPERATOR.text
            });
        }
        this.SetDocuments();
    };

    ExpandChange = (event: any) => {
        event.dataItem[event.target.props.expandField] = event.value;
        let groupName = event.dataItem.value;
        this.expandedGroups[groupName] = event.value;
        let groups = this.state.documents.data;
        let groupsLength = groups.length;
        let expandedFirst = groups[0].expanded;
        let isExpandedAllSame = true;
        for (let i = 0; i < groupsLength; i++) {
            let group = groups[i];
            if (group.expanded !== expandedFirst) {
                isExpandedAllSame = false;
                break;
            }
        }
        if (isExpandedAllSame) this.expandAll = expandedFirst;
        this.setState({});
    };

    ToggleExpandGroup = () => {
        this.expandAll = !this.expandAll;
        let gridData = this.state.documents;
        for (let kindGroup of gridData.data) {
            let groupName = kindGroup.value;
            this.expandedGroups[groupName] = this.expandAll;
            kindGroup.expanded = this.expandAll;
            for (let objGroup of kindGroup.items) {
                objGroup.expanded = this.expandAll;
            }
        }
        this.setState({documents: gridData});
    };

    AutoRefresh = () => {
        if (this.props.isActive) this.BackgroundRefresh();
    };

    BackgroundRefresh = () => {
        this.LoadData('background');
    };

    Refresh = () => {
        this.LoadData();
    };

    RefreshOnActivateCLMTab = () => { // external function call
        let bp = localStorage.getItem(CLPMSettings.CLM_BUILDPLAN_LS);
        let selectedBp = bp ? JSON.parse(bp) : undefined;
        let newBpId = selectedBp && +selectedBp.Id;
        if (this.bpId !== newBpId) {
            this.selectedBP = selectedBp;
            this.bpId = selectedBp && +selectedBp.Id;
            this.LoadData();
        }
    };

    OnIncludedFilterChange = (value: boolean | null) => {
        let gridFilter = this.gridFilter;
        let field = 'included';
        if (value === null) {
            gridFilter.filters = gridFilter.filters.filter((f) => (IsComplexGridFilter(f) || f.field !== field));
        } else {
            let oldFilter = gridFilter.filters.find((f) => (!IsComplexGridFilter(f) && f.field === field));
            if (oldFilter && !IsComplexGridFilter(oldFilter)) oldFilter.value = value;
            else gridFilter.filters.push({field, operator: 'eq', value});
        }
        this.SetDocuments();
    };

    OnSelectContextItem = (e: any) => {
        let action = e.item.data;
        this.DoAction(action);
        this.CloseContextMenu();
    };

    DoAction = async (action: string, dataItem: IDocumentItem = this.contextMenuItem!) => {
        let accept = '';
        if (action === 'UploadResult' || action === 'UploadVersion') {
            accept = dataItem.docCl
                ? '.doc,.docx,.xlsx,.xls,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                : 'image/*,video/*';
        }
        switch (action) {
            case 'DownloadSample':
                document.location.href = GetCheckListSampleUrl(dataItem.cliId, false);
                return;

            case 'DeleteResult':
                ModalRef.showDialog({
                    title: 'Confirmation',
                    text: 'Are you sure that you want to delete result? Versions count to be deleted: ' + dataItem.files.length,
                    buttons: [
                        {
                            text: 'Cancel',
                            action: () => {
                                ModalRef.hideDialog();
                            }
                        },
                        {
                            text: 'Ok',
                            color: 'primary',
                            action: () => {
                                this.DeleteResult(dataItem.resultId!); // ??
                                ModalRef.hideDialog();
                            }
                        }
                    ]
                });
                return;

            case 'UploadResult':
                await this.UploadResult(dataItem.clId, dataItem.cliId, dataItem.dsId, accept!);
                return;

            case 'UploadVersion':
                this.UploadNewVersion(dataItem.resultId!, accept);
                return;

            case 'EditCLIComments':
                this.OpenCliCommentEdit(dataItem);
                return;
        }
        // todo onSuccess ??
        /*
        function onSuccess(result) {
          if (result) {
            window.currentResultId = result;
            window.currentSelectId = dataItem.id;
            reloadChecklists();
          }
        }
        */
    };

    OpenCliCommentEdit = (dataItem: IDocumentItem) => {
        let commentRef: any = React.createRef();
        let oldValue = dataItem.comments || '';
        let disabled = !this.settings?.IsReviewer;
        ModalRef.showDialog({
            title: 'Comments',
            width: 800,
            buttons: [
                {
                    text: 'Ok',
                    color: 'primary',
                    disabled,
                    action: async () => {
                        let value = commentRef.element.current.value;
                        if (value !== oldValue) {
                            await UpdateCliComments(value, dataItem);
                            this.AutoRefresh();
                        }
                        ModalRef.hideDialog();
                    }
                },
                {
                    text: 'Cancel',
                    action: () => {
                        ModalRef.hideDialog();
                    }
                }
            ],
            children: <div>
                <TextArea
                    ref={(ref) => (commentRef = ref)}
                    rows={20}
                    style={{width: '100%'}}
                    defaultValue={oldValue}
                    readOnly={disabled}
                    maxLength={4096}
                />
            </div>
        });
    };

    GetColumnHeaderClass = (field: string, fieldId: string) => {
        let filtered = this.state.gridFilter.filters.find((filter) => {
            if (!IsComplexGridFilter(filter) && filter.field === field && filter.value) return true;
            if (IsComplexGridFilter(filter)) {
                let firstFilter = filter.filters[0];
                if (!IsComplexGridFilter(firstFilter) && firstFilter.field === fieldId) return true;
            }
            return false;
        });
        return filtered ? gridStyles.FilteredColumnTH : '';
    };

    UploadNewVersion = (resultId: number, accept: string) => {
        const $ = window.$;
        $('#uploadFile').remove();
        $('body').append(
            '<input id="uploadFile" name="uploadFile" type="file" data-resultid="' + resultId + '" style="display:none;" accept="' +
            (accept || '') +
            '"/>'
        );
        var input = $('#uploadFile');
        input.on('change', async () => {
            var selectedFile = input[0].files[0];
            let resultId = $('#uploadFile').data('resultid');
            var formData = new FormData();
            formData.append('Media', selectedFile);
            if (resultId) {
                await UploadFile(`/api/Clm/UploadResultVersion?resultId=${resultId}`, formData);
                this.LoadData();
            }
        });
        input.trigger('click');
    };

    UploadResult = (checklistId: number, itemId: number, dispatchId: number, accept: string) => {
        const $ = window.$;
        $('#uploadFile').remove();
        $('body').append('<input id="uploadFile" name="uploadFile" type="file" data-checklistid="' + checklistId +
            '" data-dispatchid="' + dispatchId + '" data-itemid="' + itemId +
            '" style="display:none;" accept="' + (accept || '') +
            '"/>'
        );
        var input = $('#uploadFile');
        input.on('change', async () => {
            this.setState({loading: true});
            var selectedFile = input[0].files[0];
            let checklistId = $('#uploadFile').data('checklistid');
            let itemId = $('#uploadFile').data('itemid');
            let dispatchId = $('#uploadFile').data('dispatchid');
            var formData = new FormData();
            formData.append('Media', selectedFile);
            let result = await UploadFile(`/api/Clm/UploadChecklistResult?checklistId=${checklistId}&itemId=${itemId}&dispatchId=${dispatchId}&WAClient=true`, formData);
            this.setState({loading: false});
            this.LoadData(); // grid??
            return result && result.resultId;
        });
        input.trigger('click');
    };

    DeleteResult = async (resultId: number) => {
        this.setState({loading: true});
        await RunScriptAsync('CLPM_DeleteResult', {ID: resultId});
        this.setState({loading: false});
        this.LoadData(); // grid??
    };

    OnClickDownloadResults = (e: any) => {
        let action: downloadCLResultsAction = e.item;
        this.DownloadResults(action);
    };

    DownloadResults = async (action: downloadCLResultsAction) => {
        let includeAll = action === 'All';
        let buildPlanId = this.bpId;
        if (!buildPlanId) return false;
        let workOrderId = this.selectedWorkOrder?.Id;
        let sasToken = await FileAccessToken.GetFileAccessToken('ViewWorkResults', buildPlanId);
        if (sasToken) {
            let url = `https://${DataServerHost}/api/Clm/GetWoResultsZip?buildPlanId=${buildPlanId}`;
            if (workOrderId) url += `&workOrderId=${workOrderId}`;
            url += `&includeAll=${includeAll}&${sasToken}`;
            window.location.href = url; // todo target _blank if conterra
        }
    };
}

export default ChecklistResults;
